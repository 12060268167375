import React from 'react';
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const CourseTemplate = ({ data }) => {
    const mr = data.markdownRemark;
    return (
        <Layout title={mr.frontmatter.title}>
            <div className="container">
                <div className="course_details">
                    <div dangerouslySetInnerHTML={{ __html: mr.html }}></div>
                </div>
            </div>
        </Layout>
    )
}

export default CourseTemplate

export const courseQuery = graphql`
  query CourseByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
        id
        html
        frontmatter {
            path
            title
            date
            description
        }
    }
}
`

